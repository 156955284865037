.collection{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;

  &:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../images/Rectangle 19.png");
    background-size: cover;
  }



  &-header{
    max-width: 100%;
    height: 230px;
    overflow: hidden;
    position: relative;

    &_img{
      width: 100%;
      height: 500px;
      margin-right: 37px;

      img{
        width: 100%;
      }
    }

    &_name{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 22px;

      p{
        color: #FFFFFF;
        font-size: 40px;
      }

      .logo{
        margin-right: 37px;
        width: 80px;
        height: 80px;

        img{
          width: 100%;
        }
      }
    }

    &_about{
      max-width: 718px;
      height: 42px;
      margin: 0 auto;

      &_wrapper{
        display: flex;
        justify-content: space-between;
        height: 100%;

        .floor{
          text-align: center;
          h6{
            font-size: 12px;
            color: #E65634;
          }

          p{
            font-size: 16px;
            color: #FFFFFF;
          }
        }

        .best{
          text-align: center;
          h6{
            font-size: 12px;
            color: #E65634;
          }

          p{
            font-size: 16px;
            color: #FFFFFF;
          }
        }

        .volume{
          text-align: center;
          h6{
            font-size: 12px;
            color: #E65634;
          }

          p{
            font-size: 16px;
            color: #FFFFFF;
          }
        }

        .total{
          text-align: center;
          h6{
            font-size: 12px;
            color: #E65634;
          }

          p{
            font-size: 16px;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  &-content{

    &_header{
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 20px;

      .active{
        border-bottom: 2px solid #FFFFFF;
      }

      p{
        font-size: 14px;
        color: #CBCBCB;
        cursor: pointer;
        padding-bottom: 7px;
      }
    }


    &_container{
      display: flex;
      justify-content: center;
      min-height: 510px;
    }

    &_list {
      width: 1170px;
      margin-top: 30px;
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
    }

    &_sidebar{
      width: 290px;
      margin-right: 32px;
      margin-top: 30px;

      .min-max-price{
        margin-bottom: 22px;
        p{
          text-align: left;
          font-size: 14px;
          color: #E65634;
        }

        .inputs{
          display: flex;
          margin-top: 10px;
          justify-content: space-between;
          .min,.max{
            width: 124px;
            height: 33px;
            background: #11112280;
            border: 0;
            padding: 0 10px 0 5px;
            font-size: 14px;
            color: #FFFFFF;
          }
        }

      }

      .pool-type{
        margin-bottom: 30px;

        &_p{
          text-align: left;
          font-size: 14px;
          color: #E65634;
          margin-bottom: 10px;
        }

        &_item{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 33px;
          background: #000000;
          border-radius: 5px;
          color: #FFFFFF;
          margin-bottom: 4px;

          p{
            padding-left: 13px;

          }

          .custom-checkbox{
            width: 15px;
            height: 15px;
            background: #2B282C;
            border: 1px solid #707070;
            margin-right: 10px;
          }
        }
      }

      .bonding-curve{

        &_p{
          text-align: left;
          font-size: 14px;
          color: #E65634;
          margin-bottom: 10px;
        }

        &_item{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 33px;
          background: #000000;
          border-radius: 5px;
          color: #FFFFFF;
          margin-bottom: 4px;

          p{
            padding-left: 13px;

          }

          .custom-checkbox{
            width: 15px;
            height: 15px;
            background: #2B282C;
            border: 1px solid #707070;
            margin-right: 10px;
          }
        }
      }
    }

  }


}
