@font-face {
  font-family: 'Lexend';
  src: url('./fonts/Lexend-Regular.ttf') format('truetype'),
  url('./fonts/Lexend-Medium.ttf') format('truetype'),
  url('./fonts/Lexend-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar {
  width: 3px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #E65634;
  border-radius: 10px;
}

body {
  margin: 0;
  font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //
  //background-image: url("./images/bg.png");
  //background-size: cover;
  background-color: #1d1424;
  background-repeat: no-repeat;
  overflow-x: hidden;
  background-image:  url("./images/bg2.png"), url("./images/bg.png");

}

@media screen and (min-width: 1920px){
  body{
    background-size: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
