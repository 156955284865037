.orange-button{
  margin-right: 15px;
  background-color: #E65634;
  border: 2px solid #FFA28D;
  width: 115px;
  height: 33px;
  background-repeat: no-repeat;
  font-size: 0.875rem;
  padding: 7px 30px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 500;

  &:hover{
    color: #000000;
    background-color: #FFFFFF;
  }
}