.poole-section{
  .poole-section-title{
    margin-bottom: 30px;

    h2{
      font-size: 40px;
      color: #FFFFFF;
      font-weight: 200;
      margin: 30px 0 11px ;
    }
    p{
      color: #8E8E8E;
      margin-bottom: 3px;
    }

    a{
      margin-top: 30px;
      display: block;
      cursor: default;
    }
  }

  .poole-section-content{
    display: flex;
    justify-content: space-evenly;
    height: 678px;

    .side-bar{
      width: 280px;
      height: 100%;
      //border: 1px solid orangered;

      @media screen and (max-width: 970px){
        display: none;
      }


      .show-hidden-pools{
        &_title{
          display: flex;
          align-items: center;
          justify-content: space-between;

          label{
            color: #E65634;
            font-size: 14px;
          }

        }

        .search{
          background: #11112280 0 0 no-repeat;
          width: 240px;
          height: 27px;
          padding: 3px 20px;
          border: 0;
          font-size: 14px;
          color: #FFFFFF;
        }
      }

      .collections{
        margin-top: 20px;
        p{
          text-align: left;
          color: #E65634;
          font-size: 14px;
          margin-bottom: 10px;
        }

        select{
          width: 280px;
          height: 33px;
          background: #000000 0 0 no-repeat;
          border-radius: 5px;
          color: #FFFFFF;
          border: 0;
        }
      }

      .schemas{
        margin-top: 20px;

        p{
          text-align: left;
          color: #E65634;
          font-size: 14px;
          margin-bottom: 10px;
        }

        .schemas-list{
          width: 262px;
          height: 188px;
          background: #11112280 0 0;
          overflow: auto;
          padding: 10px 5px 5px 13px;

          p{
            color: #FFFFFF;
            font-size: 12px;
            text-align: left;
          }
        }
      }

      .tool-type{
        margin-top: 20px;

        p{
          text-align: left;
          color: #E65634;
          font-size: 14px;
          margin-bottom: 10px;
        }

        &-block{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 280px;
          height: 33px;
          background: #000000 0 0 no-repeat padding-box;
          border-radius: 5px;
          margin-bottom: 5px;


          input[type="checkbox"] + label::before {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            background-color: #fff;
            border: 1px solid #707070;
          }

          input[type="checkbox"]:checked + label::before {
            content: "✓";
            color: #fff;
            background-color: #2B282C;
          }


          p{
            color: #FFFFFF;
            font-size: 12px;
            padding: 10px ;
            margin: 0;
          }
        }
      }

    }

    .poll-list{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      //overflow: auto;
      width: 1130px;
      //border: 1px solid orangered;
      //height: 77%;

      .pool-item{
        width: 267px;
        height: 246px;
        background: #000000 0 0;
        border-radius: 5px;
        margin: 5px 5px;


        @media screen and (max-width: 970px){
          width: 100%;
        }
      }
    }
  }





}
