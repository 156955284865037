.swap-page{
  color: #ffffff;
  width: 991px;
  margin: 0 auto;

  &_title{
    margin: 40px 0 90px 0;
    h2{
      font-size: 40px;

    }
  }


  .pool-block{
    width: 991px;
    height: 320px;
    background: #000000;
    border-radius: 5px;
    margin: 0 auto;

    &_wrapper{
      margin: 0 31px 0 31px;
      padding: 20px 0;

      .item-type{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .container{
        width: 100%;
        height: 155px;
        background: #2B282C;
        border-radius: 5px;
        margin-bottom: 17px;
        position: relative;
        margin-top: 20px;

        &-wrapper{
          margin: 0 27px 0 27px;
          padding: 15px 0 15px 0;


          .price{
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            border-bottom: 2px solid #000000;
            padding-bottom: 20px;

            &_left{


              .little-title{
                color: #D8D8D8;
                font-size: 12px;
                text-align: left;
                margin-bottom: 11px;
              }

              .price-amount{
                p{
                  font-size: 22px;
                }
              }
            }
          }

          .delta-balance{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .delta{
              text-align: left;
              .delta-title{
                margin: 10px 0 5px 0;
              }

              .delta-amount{

              }
            }

            .balance{
              text-align: right;
              .balance-title{
                margin: 10px 0 5px 0;
              }

              .balance-amount{

              }
            }
          }
        }

        .pool-owner{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0 0 0;

        }
      }
    }
  }

  .nfts-block{
    margin: 30px 0;
    text-align: left;

    h4{
      font-size: 20px;
    }

    &_list{
      margin: 20px 0;
      width: 100%;
      height: 400px;
      background-color: #000000;
      padding: 20px 0;
      overflow: hidden;


      .wrapper{
        height: 77%;
        display: flex;
        flex-wrap: wrap;
        padding: 40px;
        overflow-y: auto;
        overflow-x: hidden;
      }


      &_item{
        width: 126px;
        height: 196px;
        background: rgba(214, 204, 231, 0.32);
        margin: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover{
          transform: scale(1.1);
        }

        &.active{
          border: 1px solid #dfff00;
        }

        img{
          //width: 100%;
          height: 100%;
        }
      }
    }

  }

  .total-price-block{
    text-align: left;
    margin: 0 0 40px 0;

    h4{
      font-size: 20px;
      margin-bottom: 15px;

      span{
        color: #E65634;
      }
    }
  }

  .confirm{
    margin: 0 0 40px 0;
  }

}
