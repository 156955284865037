.pool-page-section{
  margin-top: 25px;

  &_title{
    color: #ffffff;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    .wax-title{
      display: flex;
      align-items: center;

      p{
           font-size: 40px;
      }

      img{
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }

    }

    .arrow{
      width: 24px;
      height: 24px;
      margin: 0 26px;
      img{
        width: 100%;
        height: 100%;
      }
    }

    .name-collection{
      font-size: 40px;
    }

  }

  .action-buttons{
    margin-bottom: 9px;

    .view-all{
      font-size: 14px;
      color: #ffffff;
      width: 272px;
      height: 33px;
      border: 1px solid #E65634;
      background: transparent;
      margin-right: 17px;

      &:hover{
        background: #ffffff;
        color: #000000;
      }
    }

    .direct-swap{
      font-size: 14px;
      color: #ffffff;
      width: 183px;
      height: 33px;
      border: 1px solid #E65634;
      background: transparent;

      &:hover{
        background: #ffffff;
        color: #000000;
      }
    }
  }

  .assets-block-buy{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-bottom: 17px;


    .assets{
      width: 521px;
      height: 360px;
      background: #111122;
      border-radius: 5px;
      margin-right: 10px;

      &_title{
        padding-top: 11px;
        margin-bottom: 24px;

        h5{
          font-size: 18px;
        }
      }

      .deposit-field{
        width: 440px;
        height: 76px;
        background: #000000;
        margin: 0 auto;


        .wrapper{
          height: 75%;
          padding: 10px 14px 10px 10px;
          display: flex;
          justify-content: space-between;

          .deposit-field-tokens{

            input{
              background: transparent;
              border: 1px solid #E65634;
              color: #ffffff;
              width: 200px;
              height: 25px;
            }

            .little-title{
              color: #D8D8D8;
              font-size: 12px;
              text-align: left;
              margin-bottom: 11px;
            }

            .amount-token{
              color: #FFFFFF;
              font-size: 22px;
            }
          }

          .deposit-withdraw-btn{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            .deposit-btn, .withdraw-btn, .save-btn{
              color: #FFFFFF;
              font-size: 12px;
              width: 93px;
              height: 24px;
              background: #2B282C;
              border-radius: 15px;
              border: none;

              &:hover{
                background: rgba(43, 40, 44, 0.87);
              }
            }
          }
        }
      }
    }

    .about{

      .pricing{
        width: 521px;
        height: 147px;
        background: #111122;
        border-radius: 5px;
        margin-bottom: 14px;
        position: relative;

        .edit{
          position: absolute;
          top: 13px;
          right: 40px;


          button{
            width: 93px;
            height: 24px;
            background: #2B282C;
            border-radius: 15px;
            border: none;
            color: #FFFFFF;

            &:hover{
              background: rgba(43, 40, 44, 0.87);
            }
          }
        }

        &_title{
          padding-top: 11px;
          margin-bottom: 24px;

          h5{
            font-size: 18px;
          }
        }

        &-info{
          display: flex;
          justify-content: center;
          align-items: center;

          .current-price{
            width: 218px;
            height: 76px;
            background: #000000;
            margin-right: 7px;
            text-align: left;
            justify-content: center;

            .little-title{
              color: #D8D8D8;
              font-size: 12px;
              text-align: left;
              margin-bottom: 12px;
            }


            .wrapper{
              padding: 10px 14px 10px 10px;
              height: 75%;


              .floor-price{
                font-size: 18px;
                color: #FFFFFF;
                text-align: left;
              }
            }
          }

          .delta{
            width: 218px;
            height: 76px;
            background: #000000;
            margin-right: 7px;
            text-align: left;

            .wrapper{
              padding: 10px 14px 10px 10px;
              height: 75%;

              .little-title{
                color: #D8D8D8;
                font-size: 12px;
                text-align: left;
                margin-bottom: 12px;
              }

              .delta-number{
                font-size: 18px;
                color: #ffffff;
                text-align: left;
              }
            }
          }
        }
      }

      .about-owner{
        width: 521px;
        height: 199px;
        background: #111122;
        border-radius: 5px;


        &_title{
          padding-top: 11px;
          margin-bottom: 24px;

          h5{
            font-size: 18px;
          }
        }
      }
    }
  }

  .diagram-block{
    width: 1064px;
    min-height: 487px;
    background: #111122;
    border-radius: 5px;
    margin: 0 auto 60px auto;
    padding-bottom: 20px;

    &_title{
      padding-top: 22px;
      margin-bottom: 28px;
      color: #ffffff;
      font-size: 18px;

      h5{
        font-size: 18px;
      }
    }

    .amount-slider{
      width: 227px;
      height: 99px;
      background: #000000;
      margin: 28px auto 26px auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;

      h4{
        color: #ffffff;
        font-size: 12px;
        margin-top: 5px;

        span{
          color: #E65634;
        }
      }

      p{
        color: #ffffff;
        font-size: 12px;
        margin-bottom: 5px;

        span{
          color: #E65634;
        }
      }
    }

    .diagram-content{
      width: 963px;
      height: 272px;
      margin: 0 auto;
      canvas{
        margin: 0 auto;
      }
      }

  }



  // Rodal

  .rodal-update-pool{

    .rodal-dialog{
      width: 600px!important;
      height: 540px!important;

      .rodal-title{
        color: #ffffff;
      }

      .rodal-form{
        margin-top: 40px;
        &_field{
          margin-bottom: 10px;
          width: 205px;
          input{
            border: 1px solid #E65634;
            background: transparent;
            color: #ffffff;
            padding: 10px 20px;
          }

          p{
            text-align: left;
            font-size: 12px;
            color: #ffffff;
          }
        }
      }

      .update-btn{
        margin-top: 40px;
      }
    }

    .rodal-dialog:after{
      display: none;
    }

  }

}
