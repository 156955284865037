.rodal{
  &-dialog{
    background: #2B282C;
    //width:900px!important;
    //height: 156px!important;
    padding: 15px 32px!important;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #000000;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .title{
      h2{
        font-size: 20px;
        color: #FFFFFF;
        margin-bottom: 8px;
        position: relative;
      }

      .sub-title{
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
      }
    }

    .content{
      margin-top: 45px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 65px;
      color: #FFFFFF;


      &-collections, &-schema, &-templates{
        position: relative;


        button{
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .coll-block{
          display: flex;
          align-items: center;
          justify-content: center;

          img{
            width: 18px;
            margin-right: 10px;
          }
        }

        .dropdown-menu{
          width: 94%;
          max-height: 270px;
          background-color: #000000;
          position: absolute;
          top: 40px;
          left: 0;
          z-index: 3;
          overflow-y: auto;
          overflow-x: hidden;

          .search-collection{
            width: 201px;
            height: 22px;
            background: #2B282C;
            color: #FFFFFF;
            font-size: 12px;
            margin-bottom: 6px;
            margin-top: 10px;
            padding: 0 10px;
            border: 0;

          }

          .item{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 85%;
            height: 30px;
            text-align: left;
            font-size: 12px;
            opacity: 1;
            padding: 0 10px;

            img{
              width: 20px;
              //height: 20px;
              margin-right: 10px;
            }

            .about{
              display: flex;
              align-items: flex-start;
              flex-direction: column;


              p:last-child{
                color: #8E8E8E;
              }

              .verified{
                width: 9px;
                height: 9px;

                img{
                  width: 100%;
                  height: 100%;
                  margin-left: 10px;
                }

              }
            }

            &:hover{
              background-color: #1c1c1c;
              cursor: pointer;
            }
          }
        }
      }

      &-schema{
        justify-content: flex-start;

        label{
          width: 100%;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
      }

      &-templates{
        label{
          width: 100%;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
      }

    }

  }

  .added-collections{
    position: absolute;
    bottom: -130px;
    left: 0;
    color: #ffffff;
    text-align: left;
    background: #2B282C;
    width: 900px !important;
    height: 105px !important;
    padding: 15px 32px !important;
    &_list{
      //height: 93px;
      overflow-y: auto;
      overflow-x: hidden;

      &_item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 878px;
        height: 44px;
        background: #111122;
        border-radius: 5px;
        padding: 0 15px;
        margin: 0 0 5px 0;

        .collection_name{
          display: flex;
          align-items: center;
          .collection-image{
            width: 18px;
            margin-right: 8px;

            img{
              width: 100%;
            }
          }
        }


        .close{
          cursor: pointer;
        }
      }
    }

    .confirmation{
      margin-top: 20px;
      text-align: center;
    }
  }


}
