.item{
    width: 272px;
    height: 225px;
    background: #000000;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;


    &-wrapper{
      margin: 0 11px 0 11px;

      .container{
        width: 249px;
        height: 141px;
        background: #2B282C;
        border-radius: 5px;
        margin-bottom: 17px;
        position: relative;

        &:after{
          content: "";
          height: 2px;
          width: 100%;
          background-color: #000000;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        &-wrapper{
          margin: 0 17px 0 17px;


          .price{
            padding-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;

            &-left{

              &-name{
                text-align: left;
                font-size: 11px;
                color: #8E8E8E;
                margin-bottom: 6px;
              }

              &_top_amount{
                display: flex;
                align-items: center;

                img{
                  margin-right: 6px;
                }
              }

              &-amount{
                text-align: left;
                font-size: 16px;
                color: #FFFFFF;
              }
            }

            &-right{

              &-name{
                text-align: right;
                font-size: 11px;
                color: #8E8E8E;
              }

              &-amount{
                text-align: right;
                font-size: 16px;
                color: #FFFFFF;
              }
            }
          }

          .delta-balance{
            .delta{
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              &-p{
                display: flex;
                font-size: 11px;
                color: #8E8E8E;

                p{
                  margin-right: 5px;
                }


                &-type{
                  font-size: 11px;
                  color: #FFFFFF;

                  .item-delta{
                    display: flex;
                    align-items: flex-end;

                    p{
                      margin-right: 3px;
                    }
                  }
                }
              }
              &-amount{
                font-size: 11px;
                color: #FFFFFF;
              }
            }

            .balance{
              display: flex;
              align-items: center;
              justify-content: space-between;
              &-p{
                font-size: 11px;
                color: #8E8E8E;
              }
              &-amount{
                font-size: 11px;
                color: #FFFFFF;
              }

              &-wax{
                display: flex;
                align-items: center;
                img{
                  width: 10px;
                  height: 10px;
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }

      .pool-info{
        margin: 0 3px;
        &_id{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;

          .first{
            text-align: left;
            font-size: 11px;
            color: #FFFFFF;
          }

          .second{
            text-align: right;
            font-size: 11px;
            color: #8E8E8E;
          }
        }

        &_owner{
          display: flex;
          align-items: center;
          justify-content: space-between;

          .first{
            text-align: left;
            font-size: 11px;
            color: #FFFFFF;
          }

          .second{
            text-align: right;
            font-size: 11px;
            color: #8E8E8E;
          }
        }
      }
    }

    &-type.sell{
      padding: 8px 0;
      text-align: right;
      font-size: 12px;
      letter-spacing: 0;
      color: #C269DD;
    }
  &-type.buy{
    padding: 8px 0;
    text-align: right;
    font-size: 12px;
    letter-spacing: 0;
    color: #7ACC66;
  }
  }
