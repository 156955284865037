.header {
  width: 100%;
  height: 92px;
  border-bottom: 1px solid #E65634;

  @media screen and (max-width: 1290px){
    height: 120px;
  }

  .header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 107px 0 197px;
    height: 100%;


    @media screen and (max-width: 1699px){
      padding: 0 65px 65px;
    }

    @media screen and (max-width: 1290px){
      flex-direction: column;
      justify-content: space-evenly;
    }
  }

  .header-left{
    display: flex;
    align-items: center;


    &_logo{
      width: 65px;
      height: 65px;
      position: relative;

      img{
        width: 100%;
        margin-top: 3px;
      }

      span{
        position: absolute;
        top: 20px;
        left: -12px;
        color: #FFFFFF;
        background-color: #3e1c29;
        font-weight: bold;
      }
    }

    &_search{
      margin-left: 58px;
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width: 1290px){

      }

      input{
        margin-right: 20px;
        background: #11112280 0 0 no-repeat padding-box;
        width: 284px;
        height: 27px;
        padding: 3px 20px;
        border: 0;
        font-size: 14px;
        color: #FFFFFF;
      }

      .orange-border-button{
        margin: 0;
      }
    }

    &_cart{
      width: 28px;
      height: 26px;

      img{
        width: 100%;
        height: 100%;
      }
    }
  }

  .header-right{
    display: flex;
    align-items: center;

    .header-right_logIn{
      display: flex;

      .user-block{
        min-width: 130px;
        height: 33px;
        background: #2B282C;
        border-radius: 15px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        font-size: 14px;

        .avatar{
          width: 22px;
          height: 22px;
          background: #E65634;
          border-radius: 50%;
          margin-right: 7px;
        }
      }

      .token-block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        min-width: 95px;
        height: 33px;
        background: #2B282C;
        border-radius: 15px;
        margin-right: 15px;
        padding: 0 13px 0 7px;

        .token-icon{
          margin-right: 5px;
          width: 22px;
          height: 20px;

          img{
            width: 100%;
            height: 100%;
          }
        }

        .token-amount{
          font-size: 14px;
        }
      }
    }

    &_cart{
      margin-left: 17px;
      cursor: pointer;
    }
  }


}

.header-left_search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 324px;
  max-height: 200px;
  background-color: rgba(0, 0, 0, 0.53);
  z-index: 999;
  padding: 0;
  margin: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.header-left_search-results li {
  list-style: none;
  padding: 10px;
  color: white;
  cursor: pointer;
}

.header-left_search-results li:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
