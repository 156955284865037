.MuiFormControlLabel-root {
  margin-bottom: 0px;
}

.css-j204z7-MuiFormControlLabel-root{
  margin: 0!important;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #FFFFFF;
  font-size: 16px;
  font-family: 'Lexend', sans-serif;
}

.MuiSwitch-thumb {
  background-color: #E65634!important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track,
.MuiSwitch-track {
  background-color: transparent!important;
  border: 1px solid orange;
}

.MuiButtonBase-root.MuiSwitch-switchBase .MuiSwitch-thumb {
  background-color: #8E8E8E!important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background-color: #E65634!important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
  color: #E65634!important;
}