.create-pool-title{

  margin-bottom: 52px;
    h2{
      font-size: 40px;
      color: #FFFFFF;
      font-weight: 200;
      margin: 30px 0 11px ;
    }
    p{
      color: #FFFFFF;
      margin-bottom: 3px;
    }
}

.create-pool-stepper{
  max-width: 1251px;
  min-height: 333px;
  border: 1px solid #E65634;
  border-radius: 20px;
  margin: 0 auto 80px auto;

  &_banner{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 40px;
    background: #E65634 0 0 no-repeat padding-box;
    margin: 0 auto;

    p{
      color: #FFFFFF;
      font-size: 14px;
    }
  }

  &_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    padding: 0 90px;



    .create-pool-stepper-first{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
      margin-top: 35px;

      .create-pool-stepper_select-options_item {
        width: 100%;
        height: 183px;
        cursor: pointer;
        position: relative;
        margin: 10px;

        .create-pool-stepper_select-options_item_content {
          width: 95%;
          position: absolute;
          top: 21px;
          left: 50%;
          transform: translateX(-50%);

          h4 {
            width: 100%;
            font-size: 1.25rem;
            color: #FFFFFF;
            text-align: center;
            font-weight: 500;
            margin-bottom: 15px;
          }

          .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;

            .first-img {
              width: 58px;
              height: 58px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .middle-img {
              margin: 0 4px 0 3px;
            }

            .last-img {
              width: 58px;
              height: 58px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          p {
            font-size: 12px;
            color: #FFFFFF;
          }
        }

        &:hover .create-pool-stepper_select-options_item_content svg {
          fill: rgba(255, 0, 0, 0.2);
        }

        .a:hover{
          fill: rgba(255, 0, 0, 0.2);
        }
      }

      .create-pool-stepper_select-options_item_both{
        width: 100%;
        height: 183px;
        //margin-right: 17px;
        cursor: pointer;
        position: relative;
        margin: 10px;

        h4{
          width: 100%;
          font-size: 1.25rem;
          color: #FFFFFF;
          text-align: center;
          font-weight: 500;
          position: absolute;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);
        }

        p{
          font-size: 24px;
          color: #ffffff;
          position: absolute;
          top: 100px;
          left: 50%;
          transform: translateX(-50%);
        }

        .a:hover {
          fill: rgba(255, 0, 0, 0.2);
        }
      }

      &.hidden{
        display: none;
        opacity: 0;
      }
    }

    .create-pool-stepper-second, .create-pool-stepper-third{
      margin-top: 56px;

      &_wrapper{
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 452px;
        color: #FFFFFF;

        button{
          margin: 0;
        }
        .left{


        }

        .left, .right{
          p{
            font-size: 18px;
          }

          p:nth-child(2){
            margin-bottom: 12px;
          }

          button{
            display: flex;
            align-items: center;

            img{
              width: 18px;
              margin-right: 10px;
            }
          }

          .wax-logo{
            margin-right: 10px;
          }
        }


      }


      .arrow{
        position: absolute;
        transform: matrix(0, 1, -1, 0, 0, 0);
        right: 13px;
      }

      &.hidden{
        display: none;
        opacity: 0;
      }
    }

     .create-pool-stepper-third{
       .count-block{
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-top: 34px;
         margin-bottom: 24px;

         .pool-pricing-block, .assets-amount-block{
           width: 521px;
           height: 360px;
           background: #111122;
           border-radius: 5px;
           color: #FFFFFF;

           }

           .start-price{
             width: 440px;
             height: 53px;
             margin-bottom: 15px;
             p{
               font-size: 12px;
               text-align: left;
               margin-bottom: 5px;
               color: #D8D8D8;
             }

             input[type="number"]{
               width: 358px;
               height: 33px;
               background: #000000;
               border: 1px solid #2B282C;
               color: #ffffff;
               font-size: 14px;
               padding: 0 70px 0 11px;
             }
           }

           .bonding{
             margin-bottom: 15px;

             select{
               width: 174px;
               height: 33px;
               border: 1px solid #E65634;
               background: transparent;
               color: #ffffff;
               display: block;

               option{
                 background: #130E1B;
               }
             }

             p{
               font-size: 12px;
               text-align: left;
               margin-bottom: 5px;
               color: #D8D8D8;
             }
           }

           .delta-price{
             width: 440px;
             height: 53px;
             margin-bottom: 15px;
             p{
               font-size: 12px;
               text-align: left;
               margin-bottom: 5px;
               color: #D8D8D8;
             }

             input[type="number"]{
               width: 358px;
               height: 33px;
               background: #000000;
               border: 1px solid #2B282C;
               color: #ffffff;
               font-size: 14px;
               padding: 0 70px 0 11px;
             }
           }
         }

       .pool-pricing-block{

          &_wrapper{
            margin: 0 38px;
          }

           &_title{
             margin: 11px 0;

             h6{
               font-size: 18px;
               margin-bottom: 6px;
             }

             p{
               font-size: 12px;
               color: #D8D8D8;
             }
         }
       }

       .assets-amount-block{

         &_wrapper{
           margin: 0 38px;
         }

         &_title{
           margin-top: 11px;
           margin-bottom: 32px;

           h6{
             font-size: 18px;
             color: #ffffff;
             margin-bottom: 6px;
           }

           p{
             font-size: 12px;
             color: #ffffff;
           }

         }

         &_amount{
          display: flex;
          align-items: center;
           justify-content: space-between;
           margin-bottom: 12px;
           p{
             font-size: 12px;
           }

           input[type="number"]{
             width: 183px;
             height: 33px;
             background: #000000;
             border: 1px solid #2B282C;
             color: #ffffff;
             font-size: 14px;
             padding: 0 125px 0 11px;
           }
         }
         
         &_alert{
           text-align: center;
           margin-bottom: 61px;

           p{
             font-size: 12px;

             span{
               color: #E65634;
             }
           }
         }

         &_scroll{
           width: 227px;
           height: 99px;
           background: #000000;
           margin: 0 auto;

           &-wrapper{
             margin: 0 28px 0 28px;
             padding-top: 12px;

             .amount-nft{
               font-size: 12px;
               margin-bottom: 10px;

               span{
                 color: #E65634;
               }
             }
           }

           &_scroller{
             padding: 0 28px;
             margin-bottom: 17px;
           }
         }

         &_cost{


           font-size: 12px;

           span{
             color: #E65634;
           }
         }




       }

       .diagram-block{
         width: 100%;
         height: 420px;
         background: #111122;
         border-radius: 5px;
         display: flex;
         flex-direction: column;
         align-items: center;

         canvas{
           margin: 0 auto;
           width: 920px!important;
           height: 325px!important;
         }

         &_title{
           color: #ffffff;
           margin-top: 22px;
           margin-bottom: 18px;
           h4{
             font-size: 18px;
             margin-bottom: 6px;
           }

           p{
             font-size: 12px;
           }
         }
       }

       &.hidden{
         display: none;
         opacity: 0;
       }
     }

    .create-pool-stepper-fourth{

      h5{
        margin: 15px 0 22px 0;
        font-size: 18px;
        color: #FFFFFF;
      }

      .finish-text{
        width: 495px;
        margin: 0 auto 25px auto;
        p{
          font-size: 14px;
          color: #FFFFFF;
          text-align: left;
          margin-bottom: 5px;

          span{
            color: #E65634;
          }
        }
      }

    .selected-nfts-list{
      width: 405px;
      max-height: 412px;
      overflow: auto;
      margin: 0 auto 20px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &_item{
        width: 126px;
        height: 196px;
        background: #111122;
        border-radius: 10px;
        margin-right: 6px;
        margin-bottom: 10px;
        position: relative;

        .image{
          width: 85px;
          height: 120px;
          margin: 12px auto 4px auto;
          background: white;

          img{
            width: 100%;
            height: 100%;
          }
        }

        .collection-block{
          width: 100%;
          height: 21px;
          background: #000000;
          color: #ffffff;
          font-size: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 4px;
        }

        .asset-id p{
          color: #D8D8D8;
          font-size: 10px;
          text-align: left;
          padding-left: 20px;
        }

      }
    }

      button{
        margin-right: 0;
      }


      &.hidden{
        display: none;
        opacity: 0;
      }
    }


    .stepper-footer{
      margin-top: 51px;
      margin-bottom: 23px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      .disabled{
        border: 1px solid #999999;
        color: #999999;
        cursor: default;


        &:hover{
          background: none;
          color: #999999;
        }
      }

      button{
        margin: 0;
      }


      .prev, .next{
        position: absolute;
      }

      .prev{
        top: 0;
        left: 0;

        transform: matrix(-1, 0, 0, -1, 11, 7);
      }

      .next{
        top: 8px;
        right: 11px;
      }
    }

  }




}
