.pool-page-section{
  margin-top: 25px;

  &_title{
    color: #ffffff;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    .wax-title{
      display: flex;
      align-items: center;

      p{
        font-size: 40px;
      }

      img{
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }

    }

    .arrow{
      width: 24px;
      height: 24px;
      margin: 0 26px;
      img{
        width: 100%;
        height: 100%;
      }
    }

    .name-collection{
      font-size: 40px;
    }

  }

  .assets-block-sell{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-bottom: 17px;

    .assets_title{
      margin-bottom: 12px!important;
    }


    .assets{
      width: 521px;
      height: 360px;
      background: #111122;
      border-radius: 5px;
      margin-right: 10px;

      &_title{
        padding-top: 11px;
        margin-bottom: 24px;
        font-size: 18px;
      }

      .deposit-field{
        width: 440px;
        height: 52px;
        //background: #000000;
        margin: 0 auto;


        .wrapper{
          height: 100%;
          padding: 10px 14px 10px 10px;
          display: flex;
          justify-content: space-between;

          p{
            text-align: left;
            margin-bottom: 11px;
          }

          .nfts{
            p:first-child{
              color: #D8D8D8;
              font-size: 12px;
            }
            p:last-child{
              color: #ffffff;
              font-size: 22px;
            }
          }

          .deposit-withdraw-btn{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            .deposit-btn, .withdraw-btn, .save-btn{
              color: #FFFFFF;
              font-size: 12px;
              width: 93px;
              height: 24px;
              background: #2B282C;
              border-radius: 15px;
              border: none;

              &:hover{
                background: rgba(43, 40, 44, 0.87);
              }
            }
          }

          button{
            display: block;
          }
        }
      }

      .nfts-field{
        width: 440px;
        height: 229px;
        background: #000000;
        margin: 23px auto 0 auto;

        &-list{
          display: flex;
          flex-wrap: wrap;
          height: 88%;
          padding: 10px;
          overflow: auto;
          justify-content: center;

          &_item{
            width: 120px;
            height: 160px;
            border-radius: 10px;
            background: #111122;
            margin-right: 5px;
            margin-bottom: 5px;

            &-image{
              width: 110px;
              height: 110px;
              border-radius: 10px;
              margin: 7px auto 5px auto;

              img{
                height: 100%;
              }
            }

            &-info{
              text-align: center;
              padding: 0 10px;
              p{
                font-size: 8px;
              }

              h6{
                font-size: 12px;
              }
            }
          }
        }

      }
    }

    .about{

      .pricing{
        width: 521px;
        height: 147px;
        background: #111122;
        border-radius: 5px;
        margin-bottom: 14px;
        position: relative;

        .edit{
          position: absolute;
          top: 10px;
          right: 10px;
        }

        &_title{
          padding-top: 11px;
          margin-bottom: 24px;
        }

        .info{
          display: flex;
          justify-content: center;
          align-items: center;

          .current-price{
            width: 218px;
            height: 76px;
            background: #000000;
            margin-right: 7px;
            text-align: left;
            justify-content: center;


            .wrapper{
              padding: 10px 14px 10px 10px;

              .little-title{
                color: #999999;
                font-size: 12px;
                text-align: left;
                margin-bottom: 12px;


              }
              .floor_price{
                font-size: 18px;
              }
              //p:last-child{
              //  font-size: 22px;
              //}
            }
          }

          .delta{
            width: 218px;
            height: 76px;
            background: #000000;
            margin-right: 7px;
            text-align: left;

            .wrapper{
              padding: 10px 14px 10px 10px;

              .little-title{
                color: #999999;
                font-size: 12px;
                text-align: left;
                margin-bottom: 12px;
              }

              .delta-number{
                font-size: 18px;
              }
            }
          }
        }
      }

      .about-owner{
        width: 521px;
        height: 199px;
        background: #111122;
        border-radius: 5px;


        &_title{
          padding-top: 11px;
          margin-bottom: 24px;
        }
      }
    }
  }

  .diagram-block{
    width: 1064px;
    min-height: 487px;
    background: #111122;
    border-radius: 5px;
    margin: 0 auto 60px auto;
    padding-bottom: 20px;

    &_title{
      padding-top: 22px;
      margin-bottom: 28px;
      color: #ffffff;
      font-size: 18px;
    }

    .amount-slider{
      width: 227px;
      height: 99px;
      background: #000000;
      margin: 28px auto 28px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h4{
        color: #ffffff;
        font-size: 12px;
      }

      p{
        color: #ffffff;
        font-size: 12px;
      }
    }

    canvas{
      height: 250px!important;
      width: 870px!important;
      margin: 0 auto;
    }
  }

}
