.collections-page-title{
  h2{
    font-size: 40px;
    color: #FFFFFF;
    font-weight: 200;
    margin: 30px 0 11px ;
  }
  p{
    color: #FFFFFF;
    margin-bottom: 3px;

    span{
      color: #E65634;
    }
  }
}

.collections-page-table{
  max-width: 1210px;
  margin: 0 auto;
}